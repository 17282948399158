import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import Headline5 from '../Typography/Heading/Headline5';
import grey from '../Palettes/grey';
import Body1 from '../Typography/Body/Body1';
import Body2 from '../Typography/Body/Body2';
import SpacingContainer from '../SpacingContainer';

const subscriptionCallToActionPropertyTypes = {
  callToActionMessage: PropTypes.string.isRequired,
  button: PropTypes.node.isRequired,
  showUniqueSellingPoint: PropTypes.bool,
  uniqueSellingPointMessage: PropTypes.node,
  disclaimerText: PropTypes.node,
};

type SubscriptionCallToActionProperties = InferProps<
  typeof subscriptionCallToActionPropertyTypes
>;

const Container = styled.div`
  border: 1px solid ${grey[300]};
  border-radius: 4px;
  max-width: 550px;

  a {
    color: #000;
  }
`;

const Content = styled.div`
  padding: 1.5rem;
`;

const StyledHeadline5 = styled(Headline5)`
  margin: 0 0 1.5rem;
`;

const Center = styled.div`
  text-align: center;
`;

const SubscriptionCallToAction = ({
  callToActionMessage,
  button,
  showUniqueSellingPoint,
  uniqueSellingPointMessage,
  disclaimerText,
}: SubscriptionCallToActionProperties) => (
  <Container>
    <Content>
      <StyledHeadline5>{callToActionMessage}</StyledHeadline5>
      {showUniqueSellingPoint && (
        <Body1 tag="div">{uniqueSellingPointMessage}</Body1>
      )}
      <Center>
        {button}
      </Center>
      <SpacingContainer />
      <Body2 tag="div">{disclaimerText}</Body2>
    </Content>
  </Container>
);

SubscriptionCallToAction.propTypes = subscriptionCallToActionPropertyTypes;

export default SubscriptionCallToAction;
