import React from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import SubscriptionCallToActionComponent
  from '@fuww/library/src/SubscriptionCallToAction';
import Button from '@fuww/library/src/Button';
import messages from '../../lib/messages.mjs';
import LinkAndAnchor from '../LinkAndAnchor';
import { saveRouterState } from '../../lib/routerState';

const SubscriptionCallToAction = () => {
  const intl = useIntl();
  const router = useRouter();

  return (
    <SubscriptionCallToActionComponent
      callToActionMessage={intl.formatMessage(
        messages['subscriptionCallToAction.callToAction'])}
      button={(
        <LinkAndAnchor route="subscribe">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              saveRouterState(router);
            }}
          >
            {intl.formatMessage(
              messages['subscriptionCallToAction.buttonLabel'],
            )}
          </Button>
        </LinkAndAnchor>
      )}
      showUniqueSellingPoint
      uniqueSellingPointMessage={(
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              messages['subscriptionCallToAction.uniqueSellingPointMessage'],
            ),
          }}
        />
      )}
      disclaimerText={(
        <div
        // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              messages['subscriptionCallToAction.disclaimerText'],
            ),
          }}
        />
      )}
    />
  );
};

export default SubscriptionCallToAction;
