import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import breakpoints from '../Theme/breakpoints';
import { mediumRoundedCorners } from '../RoundedCorners';
import colors from '../Theme/colors';
import Headline4 from '../Typography/Heading/Headline4';

const ArticleAccessInvitation = styled(Headline4)`
  text-align: center;
`;

const ArticleAccessVerifyPrompt = styled(Headline4)`
  text-align: center;
  padding: 0 20px;
`;

const ArticleAccessLoginContainer = styled.div`
  && {
    padding: 12px 12px 32px;
    margin: 0 auto;
    border: none;
    min-height: 362px;
    ${mediumRoundedCorners};
    a, a span, .MuiTypography-root {
      color: black;
    }
    hr {
      background-color: black;
    }

    @media (min-width: ${breakpoints.sm}px) {
      padding: 16px 16px 32px;
      margin-bottom: 32px;
      max-width: 375px;
      border: 1px solid #e0e0e0;
    }


    button {
      border: none;
    }
    button > svg {
      margin: 0 auto 16px;
      display: none;

      @media (min-width: ${breakpoints.sm}px) {
        display: block;
      }
    }
    button > img {
      width: fit-content;
    }

    .MuiTextField-root {
      margin: 20px 0 0;
      width: 100%;
      background-color: ${colors.white};
      border-radius: 4px;
    }

    .MuiButtonBase-root[type="submit"] {
      width: 100%;
      margin: 20px 0 0;
      background-color: ${colors.black};
      color: ${colors.white};
    }

    .MuiButtonBase-root[type="button"] {
      width: 100%;
      margin: 20px 0 0;
      background-color: ${colors.white};
      color: ${colors.black};
      border: 1px solid ${colors.black};
    }
  }
`;

export const InviteContainer = styled.div`
  height: 448px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const articleAccessPropertyTypes = {
  children: PropTypes.node,
  invitation: PropTypes.node,
  verifyEmailMessage: PropTypes.string,
};

type ArticleAccessProperties = InferProps<
typeof articleAccessPropertyTypes
>;

const ArticleAccess = ({
  invitation,
  verifyEmailMessage,
  children,
}: ArticleAccessProperties) => (verifyEmailMessage ? (
  <ArticleAccessLoginContainer>
    <InviteContainer>
      <ArticleAccessVerifyPrompt tag="div">
        { verifyEmailMessage }
      </ArticleAccessVerifyPrompt>
    </InviteContainer>
  </ArticleAccessLoginContainer>
) : (
  <ArticleAccessLoginContainer>
    <ArticleAccessInvitation tag="div">
      { invitation }
    </ArticleAccessInvitation>
    { children }
  </ArticleAccessLoginContainer>
));

ArticleAccess.propTypes = articleAccessPropertyTypes;

export default ArticleAccess;
