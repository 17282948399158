import React, { useMemo, useState } from 'react';
import ArticleAccess from '@fuww/library/src/Login/ArticleAccess';
import Divider from '@fuww/library/src/Login/Divider';
import EmailAccess from './EmailAccess';
import LoginWithProviderForm from './LoginWithProviderForm';
import { CaptchaProvider } from '../Captcha';
import AbTest from '../AbTest';

const headlineVariants = [
  // eslint-disable-next-line max-len
  'Join the inner circle of fashion leaders. Get exclusive access to industry intelligence, trend forecasts, and networking opportunities. Subscribe today.',
  // eslint-disable-next-line max-len
  'Get the full story. Unlock exclusive fashion industry insights, data, and analysis by subscribing today.',
  // eslint-disable-next-line max-len
  'Stay ahead in the fashion business. Access premium news, data-driven reports, and expert analysis – subscribe now for unlimited access.',
  // eslint-disable-next-line max-len
  'Become a FashionUnited insider to access premium industry news and data-driven reports. Subscribe today with your business email.',
  // eslint-disable-next-line max-len
  'Don\'t get left behind. Access the in-depth fashion industry analysis you need to succeed. Subscribe now for exclusive content.',
  // eslint-disable-next-line max-len
  'Power your fashion business decisions. Unlock exclusive news, data, and expert insights by subscribing today.',
];

const getVariantName = (_: string[], variant: string) => variant;

const ArticleAccessForm = () => {
  const [
    verifyEmailMessage, setVerifyEmailMessage,
  ] = useState<string | undefined>();
  const [conversionTracker, setConversionTracker] = useState<() => void>();

  const getConversionTracker = useMemo(
    () => (tracker: () => void) => setConversionTracker(() => tracker),
    [setConversionTracker],
  );

  return verifyEmailMessage ? (
    <ArticleAccess verifyEmailMessage={verifyEmailMessage} />
  ) : (
    <ArticleAccess
      invitation={(
        <AbTest
          name="paywallHeadlineText"
          variants={headlineVariants}
          getConversionTracker={getConversionTracker}
          getVariantName={getVariantName}
        />
      )}
    >
      <CaptchaProvider>
        <EmailAccess
          setVerifyEmailMessage={setVerifyEmailMessage}
          onSubmit={conversionTracker}
        />
      </CaptchaProvider>
      <Divider dividerText="OR CONTINUE WITH" margin="16px 0 0" />
      <LoginWithProviderForm
        provider="google"
        onClick={conversionTracker}
      />
      <LoginWithProviderForm
        provider="linkedin"
        onClick={conversionTracker}
      />
    </ArticleAccess>
  );
};

ArticleAccessForm.propTypes = {};

ArticleAccessForm.defaultProps = {};

export default ArticleAccessForm;
